<template>
  <v-card flat class="fill-height d-flex flex-column">
    <v-card-title class="pa-0">
      <v-tabs
        background-color="grey lighten-4"
        :disabled="table.loading || processing"
        mandatory
        slider-color="primary"
        v-model="tab"
        height="56"
      >
        <v-tab class="body-2 font-weight-bold text-capitalize" href="#leave">
          <v-badge color="green" :value="table.leaves.length" :content="table.leaves.length">
            <v-icon size="18" left>tw-hr-travel</v-icon>
            {{ $t(`dashboard.request_type.leave`) }}
          </v-badge>
        </v-tab>
        <v-tab class="body-2 font-weight-bold text-capitalize" href="#overtime">
          <v-badge color="green" :value="overtimeTable.items.length" :content="overtimeTable.items.length">
            <v-icon size="18" left>tw-shifts</v-icon>
            {{ $t(`dashboard.request_type.overtime`) }}
          </v-badge>
        </v-tab>
      </v-tabs>
    </v-card-title>
    <v-card-text class="pa-0 flex-grow-1">
      <v-tabs-items v-model="tab" mandatory class="fill-height">
        <v-tab-item value="leave" class="fill-height">
          <template v-if="table.leaveLoading">
            <v-card class="elevation-0 fill-height d-flex justify-center align-center">
              <v-progress-circular indeterminate size="60" color="grey lighten-3" />
            </v-card>
          </template>
          <template v-else>
            <v-data-table
              v-if="table.leaves.length"
              class="request-table"
              :items="table.leaves"
              :loading="table.leaveLoading"
              :no-data-text="table.leaveLoading ? '' : $vuetify.noDataText"
              :footer-props="{ itemsPerPageOptions: [5] }"
              hide-default-header
            >
              <template #item="row">
                <tr>
                  <td>
                    <employee-profile :employee="row.item.leaveRequest.employee" />
                  </td>
                  <td>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">{{
                        (row.item.leaveRequest.employee.employment.branch || {}).name || "-"
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="caption">{{
                        (row.item.leaveRequest.employee.employment.unit || {}).name || "-"
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </td>
                  <td>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{
                          isLanguageTurkish
                            ? row.item.leaveRequest.leaveType.name
                            : row.item.leaveRequest.leaveType.englishName || row.item.leaveRequest.leaveType.name
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="caption">
                        {{ getLeaveDays(row.item) }},
                        {{ getLeaveDate(row.item) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </td>
                  <td class="text-no-wrap text-right">
                    <template>
                      <v-btn
                        :loading="processing && row.item.id === processedId && !isApproveProcess"
                        :disabled="processing && row.item.id !== processedId"
                        color="primary"
                        class="mr-2"
                        x-small
                        depressed
                        fab
                        @click="onClickLeaveApproval(row.item, false)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-btn
                        :loading="processing && row.item.id === processedId && isApproveProcess"
                        :disabled="processing && (row.item.id !== processedId || isApproveProcess)"
                        color="success"
                        x-small
                        depressed
                        fab
                        @click="onClickLeaveApproval(row.item, true)"
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </template>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <template v-else>
              <v-card class="elevation-0 fill-height d-flex justify-center align-center">
                {{ $t("dashboard.messages.no_data_leaves") }}
              </v-card>
            </template>
          </template>
        </v-tab-item>
        <v-tab-item value="overtime" class="fill-height">
          <template v-if="overtimeTable.loading">
            <v-card class="elevation-0 fill-height d-flex justify-center align-center">
              <v-progress-circular indeterminate size="60" color="grey lighten-3" />
            </v-card>
          </template>
          <template v-else>
            <v-data-table
              v-if="overtimeTable.items.length"
              class="request-table"
              :items="overtimeTable.items"
              :loading="overtimeTable.loading"
              :no-data-text="overtimeTable.loading ? '' : $vuetify.noDataText"
              :footer-props="{ itemsPerPageOptions: [5] }"
              hide-default-header
            >
              <template #item="row">
                <tr>
                  <td>
                    <employee-profile :employee="row.item.overtime.employee" />
                  </td>
                  <td>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">{{
                        (row.item.overtime.employee.employment.branch || {}).name || "-"
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="caption">{{
                        (row.item.overtime.employee.employment.unit || {}).name || "-"
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </td>
                  <td>
                    <v-list-item-content>
                      <v-list-item-title>{{ row.item.overtime.workplan.date | formattedDate }}</v-list-item-title>
                      <v-list-item-subtitle class="caption">{{
                        getOvertimeFormat(row.item.overtime.overtimeInMinutes)
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </td>
                  <td class="text-no-wrap text-right">
                    <template>
                      <v-btn
                        :loading="processing && row.item.id === processedId && !isApproveProcess"
                        :disabled="processing && (row.item.id !== processedId || isApproveProcess)"
                        color="primary"
                        class="mr-2"
                        x-small
                        depressed
                        fab
                        @click="onClickOvertimeApproval(row.item, false)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-btn
                        :loading="processing && row.item.id === processedId && isApproveProcess"
                        :disabled="processing && (row.item.id !== processedId || !isApproveProcess)"
                        color="success"
                        x-small
                        depressed
                        fab
                        @click="onClickOvertimeApproval(row.item, true)"
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </template>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <template v-else>
              <v-card class="elevation-0 fill-height d-flex justify-center align-center">
                {{ $t("dashboard.messages.no_data_overtimes") }}
              </v-card>
            </template>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <overtime-confirm-dialog
      v-model="confirmDialogStatus"
      :data="selectedOvertime"
      :status="confirmStatus"
      @success="fetchOvertimeRequests"
      @close="confirmDialogStatus = false"
    />
  </v-card>
</template>
<script>
  import { FilterWithPaginateOvertimeRequestProcess } from "@/pages/time/query";
  import { LEAVE_APPROVAL } from "@/pages/leave/query";

  export default {
    name: "requests",
    components: {
      OvertimeConfirmDialog: () => import("@/pages/time/overtime_approval/components/confirm-dialog")
    },
    data: vm => ({
      tab: "leave",
      pendingRequestCount: 0,
      processedId: null,
      isApproveProcess: false,
      processing: false,
      confirmDialogStatus: false,
      confirmStatus: false,
      selectedOvertime: null,
      overtimeTable: {
        items: [],
        serverItemsLength: 0,
        loading: false,
        criteria: {
          manager: vm.$store.state.auth.user.id,
          requestStatus: "PENDING",
          beginDate: vm
            .$moment()
            .subtract(8, "month")
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: vm
            .$moment()
            .add(6, "month")
            .endOf("month")
            .format("YYYY-MM-DD")
        },
        options: {
          page: 1,
          itemsPerPage: 5,
          sortBy: ["overtime.requestedDateTime"],
          sortDesc: [false]
        }
      },
      table: {
        criteria: {
          beginDate: vm
            .$moment()
            .subtract(1, "month")
            .format("YYYY-MM-DD"),
          endDate: vm
            .$moment()
            .add(1, "month")
            .format("YYYY-MM-DD")
        },
        leaveLoading: false,
        overtimeLoading: false,
        leaves: [],
        overtimes: [],
        headers: [],
        totalItems: 0,
        pagination: {
          rowsPerPage: 5
        }
      }
    }),
    computed: {
      isLanguageTurkish() {
        return this.$store.state.auth.user.language === "tr";
      }
    },
    watch: {
      "overtimeTable.options": {
        deep: true,
        handler: "fetchOvertimeRequests"
      }
    },
    methods: {
      fetchLeaveRequests() {
        this.table.leaveLoading = true;
        this.$apollo
          .query({
            query: LEAVE_APPROVAL,
            variables: {
              id: this.$store.state.auth.user.id
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.table.leaves = data.employee.pendingApprovalLeaveRequests;
            }
          })
          .catch(console.error)
          .finally(() => (this.table.leaveLoading = false));
      },
      fetchOvertimeRequests() {
        this.overtimeTable.loading = true;
        this.$apollo
          .query({
            query: FilterWithPaginateOvertimeRequestProcess,
            variables: {
              criteria: this.overtimeTable.criteria,
              pagination: this.$helpers.toPaginationCriteria(this.overtimeTable.options)
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.overtimeTable.items = data.filterWithPaginateOvertimeRequestProcess.content;
              this.overtimeTable.serverItemsLength =
                data.filterWithPaginateOvertimeRequestProcess.pageInfo.totalElements;
            }
          })
          .catch(console.error)
          .finally(() => (this.overtimeTable.loading = false));
      },
      getLeaveDays(item) {
        const { leaveRequest } = item;
        const isHourly = leaveRequest.leaveRequestDays.find(item => item.duration === "HOURLY");
        if (isHourly) {
          let leaveRequestDay = leaveRequest.leaveRequestDays.reduce((acc, item) => (acc += item.lengthInMinutes));
          if (leaveRequestDay) {
            return (
              this.$helpers.convertMinutesToTime(leaveRequestDay.lengthInMinutes) +
              " " +
              (leaveRequestDay.lengthInMinutes / 60 >= 1
                ? this.$t("time_duration.hour")
                : this.$t("time_duration.minute"))
            );
          }
        } else {
          return leaveRequest.leaveRequestDays.length + " " + this.$t("date.day");
        }
      },
      getLeaveDate(item) {
        const { leaveRequest } = item;
        const isHourly = leaveRequest.leaveRequestDays.find(item => item.duration === "HOURLY");
        const isOneDayLength = leaveRequest.leaveRequestDays.length === 1;
        const beginDate = this.$moment(leaveRequest.beginLeaveRequestDay.date).format("YYYY-MM-DD");
        const endDate = this.$moment(leaveRequest.endLeaveRequestDay.date).format("YYYY-MM-DD");
        if (isHourly) {
          return `${beginDate}, ${leaveRequest.beginLeaveRequestDay.fromTime} - ${this.$moment(
            leaveRequest.beginLeaveRequestDay.fromTime,
            "HH:mm"
          )
            .add("minute", leaveRequest.beginLeaveRequestDay.lengthInMinutes)
            .format("HH:mm")}`;
        } else {
          return isOneDayLength ? beginDate : `${beginDate} - ${endDate}`;
        }
      },
      isApprover(item) {
        if (item.approver) {
          return item.approver.id === this.$store.state.auth.user.id;
        } else {
          return false;
        }
      },
      onClickLeaveApproval({ id }, status) {
        if (id) {
          this.processedId = id;
          this.isApproveProcess = status;
          const payload = {
            approved: status,
            note: ""
          };
          this.processing = true;
          this.$api.leaveRequestService
            .approve(id, payload)
            .then(({ data }) => {
              if (!data.error) {
                this.fetchLeaveRequests();
              }
            })
            .catch(console.error)
            .finally(() => {
              this.processing = false;
              this.processedId = null;
            });
        }
      },
      onClickOvertimeApproval(item, status) {
        this.selectedOvertime = item;
        this.confirmStatus = status;
        this.confirmDialogStatus = true;
      },
      getOvertimeFormat(overtime) {
        return overtime / 60 >= 1
          ? (overtime / 60).toFixed(1) + " " + this.$t("date.time")
          : overtime.toFixed(1) + " " + this.$t("date.minute");
      }
    },
    created() {
      this.fetchLeaveRequests();
      this.fetchOvertimeRequests();
    }
  };
</script>

<style scoped lang="scss">
  .request-table::v-deep {
    .v-data-table__wrapper {
      min-height: 325px;
    }
  }
</style>
